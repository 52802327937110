import UserService from '@/services/api/user.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    users: [],
    selectedUser: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getUsers(state) {
      return state.users;
    },
    getSelectedUser(state) {
      return state.selectedUser;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchAll({ commit }, filters) {
      commit('setIsLoaded', false);
      const response = await UserService.getAll(filters);
      commit('setIsLoaded', true);
      commit('setMerchants', response.data);

      return response;
    },
    async fetchAdmins() {
      return UserService.getAdmins();
    },
    async fetchUsersByCompany({ commit }) {
      commit('setIsLoaded', false);
      const response = await UserService.getUsersByCompanyId();
      commit('setIsLoaded', true);

      return response;
    },
    async store(store, user) {
      await UserService.store(user);
    },
    async update(store, user) {
      const response = await UserService.update(user);
      return response.data;
    },
    async setNewPassword(store, { id, passwordData }) {
      await UserService.setNewPassword(id, passwordData);
    },
    async patchStatus(store, { id, status }) {
      await UserService.updateStatus(id, status);
    },
    async reset2fa(store, { id }) {
      await UserService.reset2fa(id);
    },
    async setMainMerchant(store, { id }) {
      await UserService.setMainMerchant(id);
    },
    async delete(store, id) {
      await UserService.delete(id);
    },
    async resendPasswordChangeRequest(store, id) {
      await UserService.resendPasswordChangeRequest(id);
    },
    async changeComment(store, { id, comment }) {
      await UserService.changeComment(id, comment);
    },
    async assignService(store, { id, services }) {
      await UserService.assignServices(id, services);
    },
    async assignReseller(store, { id, resellerId }) {
      await UserService.assignReseller(id, resellerId);
    },
    async assignManager(store, { id, managerId }) {
      await UserService.assignManager(id, managerId);
    },
    async fetchById({ commit }, id) {
      commit('setIsLoaded', false);
      const response = await UserService.getById(id);
      commit('setSelectedUser', response.data);
      commit('setIsLoaded', true);
      return response;
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setMerchants(state, users) {
      state.users = users;
    },
    setSelectedUser(state, user) {
      state.selectedUser = user;
    },
  },
};
