import axios from 'axios';
import Vue from 'vue';
import {
  getRefreshToken,
  getTemporaryLoginRefreshToken,
  getTemporaryLoginToken,
  getToken
} from '@/services/jwt.service';
import store from '@/store';
import { eventBus } from '@/main';

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_MERCHANTO_API,
});

axiosIns.interceptors.request.use(
  config => {
    const accessToken = getTemporaryLoginToken() || getToken();

    // eslint-disable-next-line no-param-reassign
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (config.url.includes('refresh')) {
      const refreshToken = getTemporaryLoginRefreshToken() || getRefreshToken();
      config.headers.Authorization = `Bearer ${refreshToken}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

axiosIns.interceptors.response.use(
  response => {
    return response;
  },
  async function (error) {
    const originalConfig = error.config;
    if (error.response.status === 401 && error.response.data.message === 'Unauthorized') {
      if (originalConfig.url.includes('refresh')) {
        await store.dispatch('auth/logout');
        window.location = '/login';
        return Promise.reject(error);
      }

      try {
        const rs = await axiosIns.get('/auth/refresh');

        const { token, refreshToken } = rs.data;

        await store.dispatch('auth/storeTokens', { token, refreshToken });

        return axiosIns(originalConfig);
      } catch (error) {
        await store.dispatch('auth/logout');
        window.location = '/login';
        return Promise.reject(error);
      }
    } else if (error.response.status === 403 && error.response.data.message === '2fa required') {
      eventBus.$emit('2fa-dialog.show');
    }
    return Promise.reject(error);
  },
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
