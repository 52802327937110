import WebsiteStripeSettingService from '../../services/api/website-stripe-setting.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    websiteStripeSetting: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getWebsiteStripeSetting(state) {
      return state.websiteStripeSetting;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchWebsiteStripeSettingByWebsiteId({ commit }, websiteId) {
      commit('setIsLoaded', false);
      const response = await WebsiteStripeSettingService.getWebsiteStripeSettingByWebsiteId(websiteId);
      commit('setIsLoaded', true);
      commit('setWebsiteStripeSetting', response.data);

      return response.data;
    },
    async saveWebsiteStripeSetting(store, { websiteId, stripeSetting }) {
      const response = await WebsiteStripeSettingService.saveWebsiteStripeSetting(websiteId, stripeSetting);
      store.commit('setWebsiteStripeSetting', response.data);
      return response.data;
    },
    async deleteWebsiteStripeSettingByWebsiteId(store, { websiteId }) {
      await WebsiteStripeSettingService.deleteWebsiteStripeSettingByWebsiteId(websiteId);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setWebsiteStripeSetting(state, stripeSetting) {
      state.stripeSetting = stripeSetting;
    },
  },
};
