import axiosIns from '@/plugins/axios';

class ProfileService {
  me() {
    return axiosIns.get('/profile/me');
  }

  verifyPhone() {
    return axiosIns.post('/profile/phone/verify');
  }

  generateAuthenticatorQr() {
    return axiosIns.post('/profile/2fa/generate-authenticator-qr');
  }

  validatePhoneVerification(code) {
    return axiosIns.post('/profile/phone/verify/code', code);
  }

  enable2fa() {
    return axiosIns.post('/profile/enable-2fa');
  }

  send2fa(email) {
    return axiosIns.post('/profile/2fa/send', email);
  }

  check2fa(code) {
    return axiosIns.post('/profile/2fa/check', code);
  }

  patchPhone(phone) {
    return axiosIns.patch('/profile/phone', phone);
  }

  patchTwoFaType(twoFaType) {
    return axiosIns.patch('/profile/2fa/type', twoFaType);
  }
}

export default new ProfileService();
