import axiosIns from '@axios';

class PaymentAcquirerService {
  getPaymentAcquirers() {
    return axiosIns.get('/payment-acquirers');
  }

  createPaymentAcquirer(paymentAcquirer) {
    return axiosIns.post('/payment-acquirers', paymentAcquirer);
  }

  updatePaymentAcquirer(id, paymentAcquirer) {
    return axiosIns.put(`/payment-acquirers/${id}`, paymentAcquirer);
  }

  deletePaymentAcquirer(id) {
    return axiosIns.delete(`/payment-acquirers/${id}`);
  }
}

export default new PaymentAcquirerService();
