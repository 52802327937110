import WebsitePaymentSettingService from '../../services/api/website-payment-settings.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    websitePaymentSettings: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getWebsitePaymentSettings(state) {
      return state.websitePaymentSettings;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchWebsitePaymentSettingsByWebsiteId({ commit }, websiteId) {
      commit('setIsLoaded', false);
      const response = await WebsitePaymentSettingService.getWebsitePaymentSettingsByWebsiteId(websiteId);
      commit('setIsLoaded', true);
      commit('setWebsitePaymentSettings', response.data);

      return response.data;
    },
    async saveWebsitePaymentSettings(store, { websiteId, paymentSettings }) {
      const response = await WebsitePaymentSettingService.saveWebsitePaymentSettings(websiteId, paymentSettings);
      store.commit('setWebsitePaymentSettings', response.data);
      return response.data;
    },
    async generateApiKeys(store, websiteId) {
      const response = await WebsitePaymentSettingService.generateApiKeys(websiteId);
      store.commit('setWebsitePaymentSettings', response.data);
      return response.data;
    },
    async generateRSAKeys(store, websiteId) {
      return WebsitePaymentSettingService.generateRSAKeys(websiteId);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setWebsitePaymentSettings(state, websitePaymentSettings) {
      state.websitePaymentSettings = websitePaymentSettings;
    },
  },
};
