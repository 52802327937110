import axiosIns from '@axios';

class ChargebackMerchantApiService {
  putMerchantApiSettingsByCompanyId(companyId, merchantApiSettings) {
    return axiosIns.put(`/chargeback-merchant-api/settings/company/${companyId}`, merchantApiSettings);
  }

  getMerchantApiSettingsByCompanyId(companyId) {
    return axiosIns.get(`/chargeback-merchant-api/settings/company/${companyId}`);
  }
}

export default new ChargebackMerchantApiService();
