import { isEmpty } from './index';

export const required = value => (value ? true : 'This field is required');

export const requiredExtended = value => (!isEmpty(value) ? true : 'This field is required');

export const emailValidator = value => {
  if (isEmpty(value)) {
    return true;
  }

  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)));
  }

  return re.test(String(value)) || 'The Email field must be a valid email';
};

export const passwordValidator = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password);

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword || 'Field must contain at least one uppercase, lowercase and digit with min 8 chars'
  );
};

export const passwordValidatorIf = (password, isValidate) => {
  /* eslint-disable no-useless-escape */
  if (isValidate) {
    return true;
  }
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password);

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword || 'Field must contain at least one uppercase, lowercase and digit with min 8 chars'
  );
};

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || 'The Confirm Password field confirmation does not match';

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value);

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `Enter number between ${min} and ${max}`;
};

export const binValidator = (value) => {
  const valueAsNumber = Number(value);

  return (Number(400000) <= valueAsNumber && Number(499999) >= valueAsNumber) || 'Bin entered is incorrect';
};

export const integerValidator = value => {
  if (isEmpty(value)) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)));
  }

  return /^-?[0-9]+$/.test(String(value)) || 'This field must be an integer';
};

export const regexValidator = (value, regex) => {
  if (isEmpty(value)) {
    return true;
  }

  let regeX = regex;
  if (typeof regeX === 'string') {
    regeX = new RegExp(regeX);
  }

  if (Array.isArray(value)) {
    return value.every(val => regexValidator(val, { regeX }));
  }

  return regeX.test(String(value)) || 'The Regex field format is invalid';
};

export const alphaValidator = value => {
  if (isEmpty(value)) {
    return true;
  }

  // const valueAsString = String(value)

  return /^[A-Z]*$/i.test(String(value)) || 'The Alpha field may only contain alphabetic characters';
};

export const urlValidator = value => {
  if (value === undefined || value === null || value.length === 0) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;

  return re.test(value) || 'URL is invalid';
};

export const lengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true;
  }

  return value.length === length || `The Min Character field must be at least ${length} characters`;
};

export const maxLengthValidator = (value, maxLength) => {
  if (isEmpty(value)) {
    return `The Max Character field must be at least ${maxLength} characters`;
  }

  return value.length < maxLength || `The Max Character field must no more than  ${maxLength} characters`;
};

export const alphaDashValidator = value => {
  if (isEmpty(value)) {
    return true;
  }

  const valueAsString = String(value);

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'All Character is not valid';
};

export const cleanSpace = (data) => {
  const fields = ['email', 'phone', 'billingEmail', 'taxId', 'mcc', 'bin', 'caid'];
  fields.forEach(field => {
    if (data[field]) {
      data[field] = data[field].replace(/\s+/g, '');
    }
    if (data['company'] && data['company'][field]) {
      data['company'][field] = data['company'][field].replace(/\s+/g, '');
    }
  });
  return data;
};

export const percentageValidator = value => {
  if (isEmpty(value)) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.every(val => /^(?:100|[0-9][0-9]?)$/.test(String(val)));
  }

  return /^(?:100|[0-9][0-9]?)$/.test(String(value)) || 'This field must be a number between 0 and 100';
};

export const positiveNumberValidator = value => {
  const valueAsNumber = Number(value);

  return (valueAsNumber > 0) || 'This field must be a positive number';
};

export const lessThanOrEqualValidator = (firstValue, secondValue, errorMessage) => {
  if (Number(firstValue) > Number(secondValue)) {
    return errorMessage ?? 'This field must be less than or equal to the other field';
  }
  return true;
};
