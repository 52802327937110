import WebsitePricesService from '@/services/api/website-prices.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    prices: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getPrices(state) {
      return state.prices;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchByWebsiteId({ commit }, { websiteId }) {
      commit('setIsLoaded', false);
      const response = await WebsitePricesService.getByWebsiteId(websiteId);
      commit('setIsLoaded', true);
      commit('setPrices', response.data);

      return response.data;
    },
    async update(store, { websiteId, price }) {
      await WebsitePricesService.update(websiteId, price);
    },
    async recalculateChargebackPrices() {
      await WebsitePricesService.recalculateChargebackPrices();
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setPrices(state, prices) {
      state.prices = prices;
    },
  },
};
