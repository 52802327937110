import axiosIns from '@axios';

class ChargebackReportService {
  getReport(filters) {
    Object.keys(filters).forEach(k => filters[k] == null && delete filters[k]);
    const query = new URLSearchParams(filters);

    return axiosIns.get(`/chargeback-reports?${query}`);
  }
  getWebsiteActivity(filters) {
    Object.keys(filters).forEach(k => filters[k] == null && delete filters[k]);
    const query = new URLSearchParams(filters);

    return axiosIns.get(`/chargeback-reports/website-activity?${query}`);
  }
}

export default new ChargebackReportService();
