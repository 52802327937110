import axiosIns from '@/plugins/axios';

class AuthService {
  async profile() {
    return await axiosIns.get('/profile/me');
  }

  async login(userData) {
    return await axiosIns.post('/auth/sign-in', userData);
  }

  async code(code) {
    return await axiosIns.post('/auth/sign-in/verify/code', code);
  }

  async register(userData) {
    return await axiosIns.post('/auth/sign-up', userData);
  }

  async forgotPassword(email) {
    return await axiosIns.post('/auth/email/forgot-password', email);
  }

  async resetPassword(passwordData) {
    return await axiosIns.post('/auth/email/reset-password', passwordData);
  }

  async verifyToken(token) {
    return await axiosIns.get(`/auth/email/verify/${token}`);
  }

  async getLoginToken(id) {
    return await axiosIns.get(`/auth/merchant/get-login-token/${id}`);
  }

  async loginAsMerchant(token) {
    return await axiosIns.post('/auth/merchant/login-as-merchant', { token });
  }
}

export default new AuthService();
