import axiosIns from '@axios';

class ManagerBillingService {
  getAll(filters) {
    Object.keys(filters).forEach(k => filters[k] == null && delete filters[k]);
    const query = new URLSearchParams(filters);

    return axiosIns.get(`/manager-billings?${query}`);
  }

  getById(id) {
    return axiosIns.get(`/manager-billings/${id}`);
  }

  bulkPatchStatus(ids, status) {
    return axiosIns.patch('/manager-billings/status', {
      ids,
      status,
    });
  }

  deleteInvoice(id) {
    return axiosIns.delete(`/manager-billings/${id}`);
  }

  deleteInvoices(ids) {
    return axiosIns.post('/manager-billings/delete/invoices', {
      ids,
    });
  }
}

export default new ManagerBillingService();
