import ChargebackPriceConfigService from '@/services/api/chargebacks/chargeback-price-config.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    eventPriceConfig: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getEventPriceConfig(state) {
      return state.eventPriceConfig;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchEventPriceConfig({ commit }) {
      commit('setIsLoaded', false);
      const response = await ChargebackPriceConfigService.getEventPriceConfig();
      commit('setIsLoaded', true);
      commit('setEventPriceConfig', response.data);

      return response.data;
    },
    async patchEventPrice(store, chargebackEventPrice) {
      await ChargebackPriceConfigService.updateEventPrice(chargebackEventPrice);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setEventPriceConfig(state, eventPriceConfig) {
      state.eventPriceConfig = eventPriceConfig;
    },
  },
};
