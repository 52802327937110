import ChargebackStatisticService from '@/services/api/chargebacks/chargeback-statistic.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    eventStatistics: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getEventStatistics(state) {
      return state.eventStatistics;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchEventStatistics({ commit }, filters) {
      commit('setIsLoaded', false);
      const response = await ChargebackStatisticService.getEventStatistics(filters);

      commit('setIsLoaded', true);
      commit('setEventStatistics', response.data);

      return response;
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setEventStatistics(state, eventStatistics) {
      state.eventStatistics = eventStatistics;
    },
  },
};
