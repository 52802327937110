import axiosIns from '@/plugins/axios';

class ApplicationService {
  getApplications(filters) {
    Object.keys(filters).forEach(k => filters[k] == null && delete filters[k]);
    const query = new URLSearchParams(filters);

    return axiosIns.get(`/applications?${query}`);
  }

  getApplicationById(id) {
    return axiosIns.get(`/applications/${id}`);
  }

  deleteApplication(id) {
    return axiosIns.delete(`/applications/${id}`);
  }
}

export default new ApplicationService();
