import ResellerBillingService from '@/services/api/reseller-billing.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    resellerBillings: [],
    selectedResellerBilling: null,
    billingHistory: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getResellerBillings(state) {
      return state.resellerBillings;
    },
    getSelectedResellerBilling(state) {
      return state.selectedResellerBilling;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchAll({ commit }, filters) {
      commit('setIsLoaded', false);
      const response = await ResellerBillingService.getAll(filters);
      commit('setIsLoaded', true);
      commit('setResellerBillings', response.data);

      return response;
    },
    async fetchById({ commit }, { id }) {
      commit('setIsLoaded', false);
      const response = await ResellerBillingService.getById(id);
      commit('setSelectedResellerBilling', response.data);
      commit('setIsLoaded', true);
      return response;
    },
    async bulkPatchStatus(store, { ids, status }) {
      await ResellerBillingService.bulkPatchStatus(ids, status);
    },
    async deleteInvoices(store, { ids }) {
      await ResellerBillingService.deleteInvoices(ids);
    },
    async deleteInvoice(store, { id }) {
      await ResellerBillingService.deleteInvoice(id);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setResellerBillings(state, resellerBillings) {
      state.resellerBillings = resellerBillings;
    },
    setSelectedResellerBilling(state, resellerBilling) {
      state.selectedResellerBilling = resellerBilling;
    },
  },
};
