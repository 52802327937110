import CompanyService from '@/services/api/company.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    companies: [],
    selectedCompany: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getCompanies(state) {
      return state.companies;
    },
    getSelectedCompany(state) {
      return state.selectedCompany;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchCompanies({ commit }) {
      commit('setIsLoaded', false);
      const response = await CompanyService.getCompanies();
      commit('setIsLoaded', true);
      commit('setCompanies', response.data);

      return response;
    },
    async fetchCompanyById({ commit }, id) {
      commit('setIsLoaded', false);
      const response = await CompanyService.getCompanyById(id);
      commit('setSelectedCompany', response.data);
      commit('setIsLoaded', true);
      return response;
    },

    async patchCompany(store, { id, company }) {
      return await CompanyService.patchCompany(id, company);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setCompanies(state, companies) {
      state.companies = companies;
    },
    setSelectedCompany(state, company) {
      state.selectedCompany = company;
    },
  },
};
