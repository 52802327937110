import axiosIns from '@axios';

class PaymentSubscriptionService {
  getPaymentSubscriptions(filters) {
    Object.keys(filters).forEach(k => filters[k] == null && delete filters[k]);
    const query = new URLSearchParams(filters);

    return axiosIns.get(`/payment-subscriptions?${query}`);
  }

  getPaymentSubscriptionById(id) {
    return axiosIns.get(`/payment-subscriptions/${id}`);
  }
}

export default new PaymentSubscriptionService();
