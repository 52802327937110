import {
  destroyToken,
  getToken,
  saveToken,
  getUserFromToken,
  setProfile,
  getTemporaryLoginToken,
  saveTemporaryLoginToken,
  destroyTemporaryLoginToken,
  saveTemporaryLoginTokenExpirationDate,
  deleteTemporaryLoginTokenExpirationDate,
  getTemporaryLoginTokenExpirationDate,
  getProfile,
  destroyTemporaryProfile,
  saveTemporaryProfile,
  getRefreshToken,
  saveRefreshToken,
  getTemporaryLoginRefreshToken, saveTemporaryLoginRefreshToken,
} from '@/services/jwt.service';
import AuthService from '../../services/api/auth.service';
import dayjs from 'dayjs';

const getDefaultState = () => {
  return {
    token: getToken() || undefined,
    refreshToken: getRefreshToken() || undefined,
    temporaryLoginToken: getTemporaryLoginToken() || undefined,
    temporaryLoginRefreshToken: getTemporaryLoginRefreshToken() || undefined,
    temporaryLoginTokenExpirationDate: getTemporaryLoginTokenExpirationDate() || undefined,
    loggedInAsMerchant: !!getTemporaryLoginToken(),
    user: getUserFromToken(),
    profile: getProfile(),
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    isLoggedIn: state => !!state.token,
    user: state => state.user,
    profile: state => state.profile,
  },
  actions: {
    async login({ commit }, userData) {
      const response = await AuthService.login(userData);

      if (response.data && response.data.token) {
        commit('setToken', response.data.token);
        commit('setRefreshToken', response.data.refreshToken);
      }
      return response.data;
    },
    async code({ commit }, code) {
      const response = await AuthService.code(code);

      if (response.data && response.data.token) {
        commit('setToken', response.data.token);
        commit('setRefreshToken', response.data.refreshToken);
      }
      return response.data;
    },

    async profile({ commit }) {
      const response = await AuthService.profile();
      await commit('setProfile', response.data);
    },

    async getProfile() {
      const response = await AuthService.profile();
      return response.data;
    },

    async forgotPassword(store, email) {
      return await AuthService.forgotPassword(email);
    },

    async verifyToken(store, token) {
      const response = await AuthService.verifyToken(token);
      return response.data;
    },

    async resetPassword(store, passwordData) {
      return await AuthService.resetPassword(passwordData);
    },

    async getLoginToken(store, id) {
      const response = await AuthService.getLoginToken(id);
      return response.data;
    },

    async loginAsMerchant({ commit }, token) {
      try {
        const response = await AuthService.loginAsMerchant(token);

        if (response.data && response.data.token) {
          commit('setTemporaryLoginToken', response.data.token);
          commit('setTemporaryLoginRefreshToken', response.data.refreshToken);
          commit('setTemporaryLoginTokenExpirationDate', dayjs().add(20, 'minutes'));
          commit('setLoggedInAsMerchant', true);
          commit('setTemporaryProfile', response.data.user);
          commit('setUser', getUserFromToken());
          return true;
        }
      } catch (e) {
        return false;
      }
    },

    async logout({ commit }) {
      commit('destroyToken');
    },

    async merchantLogout({ commit }) {
      commit('destroyTemporaryLoginToken');
      commit('deleteTemporaryLoginTokenExpirationDate');
      commit('setLoggedInAsMerchant', false);
      commit('destroyTemporaryProfile');
      commit('setUser', getUserFromToken());
    },

    async register(store, userData) {
      return await AuthService.register(userData);
    },
    async storeTokens(state, { token, refreshToken }) {
      if (state.temporaryLoginToken) {
        state.commit('setTemporaryLoginToken', token);
        state.commit('setTemporaryLoginRefreshToken', refreshToken);
      } else {
        state.commit('setToken', token);
        state.commit('setRefreshToken', refreshToken);
      }
    }
  },
  mutations: {
    setProfile(state, profile) {
      state.profile = profile;
      setProfile(profile);
    },

    setTemporaryProfile(state, profile) {
      state.profile = profile;
      saveTemporaryProfile(profile);
    },

    setToken(state, token) {
      state.token = token;
      saveToken(token);
    },

    setRefreshToken(state, token) {
      state.refreshToken = token;
      saveRefreshToken(token);
    },

    setUser(state, user) {
      state.user = user;
    },

    setTemporaryLoginToken(state, token) {
      state.temporaryLoginToken = token;
      saveTemporaryLoginToken(token);
    },

    setTemporaryLoginRefreshToken(state, token) {
      state.temporaryLoginRefreshToken = token;
      saveTemporaryLoginRefreshToken(token);
    },

    setTemporaryLoginTokenExpirationDate(state, date) {
      state.temporaryLoginTokenExpirationDate = date;
      saveTemporaryLoginTokenExpirationDate(date);
    },

    deleteTemporaryLoginTokenExpirationDate(state) {
      state.temporaryLoginTokenExpirationDate = null;
      deleteTemporaryLoginTokenExpirationDate();
    },

    destroyTemporaryProfile(state) {
      destroyTemporaryProfile();
      state.profile = getProfile();
    },

    setLoggedInAsMerchant(state, loggedIn) {
      state.loggedInAsMerchant = loggedIn;
    },

    destroyToken(state) {
      state.token = null;
      state.refreshToken = null;
      destroyToken();
    },

    destroyTemporaryLoginToken(state) {
      state.temporaryLoginToken = null;
      destroyTemporaryLoginToken();
    },
  },
};
