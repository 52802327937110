import axiosIns from '@axios';

class BillingService {
  getBillings(filters) {
    Object.keys(filters).forEach(k => filters[k] == null && delete filters[k]);
    const query = new URLSearchParams(filters);

    return axiosIns.get(`/billings?${query}`);
  }

  getBillingsByMerchantId(merchantId, filters) {
    Object.keys(filters).forEach(k => filters[k] == null && delete filters[k]);
    const query = new URLSearchParams(filters);

    return axiosIns.get(`/billings/merchant/:merchantId?${query}`);
  }

  getBillingById(id) {
    return axiosIns.get(`/billings/${id}`);
  }

  getHistory(id) {
    return axiosIns.get(`/billings/${id}/history`);
  }

  bulkPatchStatus(ids, status) {
    return axiosIns.patch('/billings/status', {
      ids,
      status,
    });
  }

  // Write customCorrection to the backend
  customCorrection(id, customCorrection) {
    return axiosIns.post(`/billings/${id}/custom-correction`, customCorrection);
  }

  sendInvoices(ids) {
    return axiosIns.post('/billings/send/invoices', {
      ids,
    });
  }

  deleteInvoices(ids) {
    return axiosIns.post('/billings/delete/invoices', {
      ids,
    });
  }

  downloadInvoice(id) {
    return axiosIns
      .get(`/billings/${id}/download`, {
        responseType: 'blob',
      })
      .then(response => response.data);
  }

  downloadManagerInvoice(id) {
    return axiosIns
      .get(`/reseller-billings/${id}/download`, {
        responseType: 'blob',
      })
      .then(response => response.data);
  }

  downloadReceipt(id) {
    return axiosIns
      .get(`/billings/${id}/download-receipt`, {
        responseType: 'blob',
      })
      .then(response => response.data);
  }

  addNewInvoice(invoicePayload) {
    if (invoicePayload.companyId) {
      return axiosIns.post(`/billings/company/${invoicePayload.companyId}/generate`, invoicePayload).then(response => response.data);
    }

    return axiosIns.post('/billings/generate', invoicePayload).then(response => response.data);
  }

  deleteInvoice(id) {
    return axiosIns.delete(`/billings/${id}`);
  }

  getBillingCredentials(filters) {
    Object.keys(filters).forEach(k => filters[k] == null && delete filters[k]);
    const query = new URLSearchParams(filters);

    return axiosIns.get(`/billing-credentials?${query}`);
  }

  getDefaultBillingCredentials(currency) {
    return axiosIns.get(`/billing-credentials/default/${currency}`);
  }

  saveBillingCredentials(payload) {
    return axiosIns.post('/billing-credentials', payload);
  }

  updateBillingCredentials(id, billingCredentials) {
    return axiosIns.put(`/billing-credentials/${id}`, billingCredentials);
  }
}

export default new BillingService();
