import '@/plugins/vue-composition-api';
import '@/styles/styles.scss';
import VueCookies from 'vue-cookies';
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import Notifications from 'vue-notification';
import router from './router';
import store from './store';
import VueConfirmDialog from 'vue-confirm-dialog';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import VueMask from 'v-mask';

export const eventBus = new Vue();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');

Vue.use(VueConfirmDialog);
Vue.component('VueConfirmDialog', VueConfirmDialog.default);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

moment.tz.setDefault('UTC');
Vue.use(VueMoment, { moment });
Vue.use(Notifications);
Vue.use(VueCookies);
Vue.use(VueMask);
