import ChargebackEventService from '../../../services/api/chargebacks/chargeback-event.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    events: [],
    selectedEvent: null,
    selectedEventTableHeaders: localStorage.getItem('merchanto-selected-events-table-headers')
      ? JSON.parse(localStorage.getItem('merchanto-selected-events-table-headers'))
      : [],
    selectedEventLogs: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getEvents(state) {
      return state.events;
    },
    getSelectedEvent(state) {
      return state.selectedEvent;
    },
    getSelectedEventLogs(state) {
      return state.selectedEventLogs;
    },
    getSelectedEventTableHeaders(state) {
      return state.selectedEventTableHeaders;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchEvents({ commit }, filters) {
      commit('setIsLoaded', false);
      const response = await ChargebackEventService.getEvents(filters);
      commit('setIsLoaded', true);
      commit('setEvents', response.data);

      return response;
    },
    async fetchEventsByMerchantId({ commit }, { id, filters }) {
      commit('setIsLoaded', false);
      const response = await ChargebackEventService.getEventsByMerchantId(id, filters);
      commit('setIsLoaded', true);
      commit('setEvents', response.data);

      return response;
    },
    async bulkUpdateEventsStatus({ commit }, data) {
      commit('setIsLoaded', false);
      const response = await ChargebackEventService.bulkUpdateEventsStatus(data);
      commit('setIsLoaded', true);
      return response.data;
    },
    async downloadReport({ commit }, name) {
      commit('setIsLoaded', false);
      await ChargebackEventService.downloadReport(name);
      commit('setIsLoaded', true);
    },
    async exportEvents({ commit }, filters) {
      commit('setIsLoaded', false);
      await ChargebackEventService.exportEvents(filters);
      commit('setIsLoaded', true);
    },
    async generateFakeEvents({ commit }, fakeEvents) {
      commit('setIsLoaded', false);
      await ChargebackEventService.generateFakeEvents(fakeEvents);
      commit('setIsLoaded', true);
    },
    async fetchEventById({ commit }, { id }) {
      commit('setIsLoaded', false);
      const response = await ChargebackEventService.getEventById(id);
      commit('setSelectedEvent', response.data);
      commit('setIsLoaded', true);
      return response;
    },
    async fetchEventLogsById({ commit }, { id }) {
      commit('setIsLoaded', false);
      const response = await ChargebackEventService.getEventLogsById(id);
      commit('setSelectedEventLogs', response.data);
      commit('setIsLoaded', true);
      return response;
    },
    async patchStatus(store, { id, status, refunded, comment, reason }) {
      await ChargebackEventService.updateStatus(id, status, refunded, comment, reason);
    },
    async manualUpdated(store, { id, isManualUpdated }) {
      await ChargebackEventService.isManualUpdated(id, isManualUpdated);
    },
    async uploadFile(store, formData) {
      return ChargebackEventService.uploadFile(formData);
    },
    async eventAnalytics(store, formData) {
      return ChargebackEventService.eventAnalytics(formData);
    },
    async descriptorAnalytics(store, formData) {
      return ChargebackEventService.descriptorAnalytics(formData);
    },
    async markKnown(store, payload) {
      return ChargebackEventService.markKnown(payload);
    },

  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setEvents(state, events) {
      state.events = events;
    },
    setSelectedEvent(state, event) {
      state.selectedEvent = event;
    },
    setSelectedEventLogs(state, eventLogs) {
      state.selectedEventLogs = eventLogs;
    },
    selectedEventTableHeaders(state, selectedEventTableHeaders) {
      localStorage.setItem('merchanto-selected-events-table-headers', JSON.stringify(selectedEventTableHeaders));
      state.selectedEventTableHeaders = selectedEventTableHeaders;
    },
  },
};
