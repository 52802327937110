import { ref } from '@vue/composition-api';
import { getProfile } from '@/services/jwt.service';
import Roles from '@/enums/roles.enum';

export default () => {
  const resolveNavItemComponent = item => {
    const profile = getProfile();
    if (item.roles && item.roles.length && !item.roles.includes(profile.role)) {
      return;
    }

    if (item.managerBillingAccess && !profile.managerBillingAccess) {
      return;
    }

    if (profile.role !== Roles.ADMIN) {
      if (item.services && item.services.length) {
        let value = null;
        if (profile.services) {
          profile.services.forEach((service) => {
            if (item.services.includes(service)) {
              if (item.subheader) {
                value = 'vertical-nav-menu-section-title';
              } else if (item.children) {
                value = 'vertical-nav-menu-group';
              } else {
                value = 'vertical-nav-menu-link';
              }
            }
          });
        }
        return value;
      }
    }
    if (item.subheader) return 'vertical-nav-menu-section-title';
    if (item.children) return 'vertical-nav-menu-group';

    return 'vertical-nav-menu-link';
  };

  const isMouseHovered = ref(false);

  return {
    isMouseHovered,
    resolveNavItemComponent,
  };
};
