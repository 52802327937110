import VisaOipSettingsService from '../../services/api/visa-oip.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    visaOipSettings: {},
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getVisaOipSettings(state) {
      return state.visaOipSettings;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchVisaOipSettingsByWebsiteId({ commit }, websiteId) {
      commit('setIsLoaded', false);
      const response = await VisaOipSettingsService.getVisaOipSettingsByWebsiteId(websiteId);
      commit('setIsLoaded', true);
      commit('setVisaOipSettings', response.data);
      return response.data;
    },
    async updateVisaOipSettingsByWebsiteId(store, { websiteId, visaOipSettings }) {
      await VisaOipSettingsService.putVisaOipSettingsByWebsiteId(websiteId, visaOipSettings);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setVisaOipSettings(state, visaOipSettings) {
      state.visaOipSettings = visaOipSettings;
    },
  },
};
