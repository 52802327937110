import ApplicationService from '@/services/api/application.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    applications: [],
    selectedApplication: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getApplications(state) {
      return state.applications;
    },
    getSelectedApplication(state) {
      return state.selectedApplication;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchApplications({ commit }, filters) {
      commit('setIsLoaded', false);
      const response = await ApplicationService.getApplications(filters);
      commit('setIsLoaded', true);
      commit('setApplications', response.data);

      return response;
    },
    async deleteApplication(store, { id }) {
      await ApplicationService.deleteApplication(id);
    },
    async fetchApplicationById({ commit }, { id }) {
      commit('setIsLoaded', false);
      const response = await ApplicationService.getApplicationById(id);
      commit('setSelectedApplication', response.data);
      commit('setIsLoaded', true);
      return response;
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setApplications(state, applications) {
      state.applications = applications;
    },
    setSelectedApplication(state, application) {
      state.selectedApplication = application;
    },
  },
};
