import ChargebackMerchantApiService from '@/services/api/chargebacks/chargeback-merchant-api.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    merchantApiSettings: {},
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getMerchantApiSettings(state) {
      return state.merchantApiSettings;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchMerchantApiSettingsByCompanyId({ commit }, companyId) {
      commit('setIsLoaded', false);
      const response = await ChargebackMerchantApiService.getMerchantApiSettingsByCompanyId(companyId);
      commit('setIsLoaded', true);
      commit('setMerchantApiSettings', response.data);
      return response.data;
    },
    async updateMerchantApiSettingsByCompanyId(store, { companyId, merchantApiSettings }) {
      await ChargebackMerchantApiService.putMerchantApiSettingsByCompanyId(companyId, merchantApiSettings);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setMerchantApiSettings(state, merchantApiSettings) {
      state.merchantApiSettings = merchantApiSettings;
    },
  },
};
