import axiosIns from '@axios';

class ChargebackFileService {
  getVisaOipFiles() {
    return axiosIns.get('/chargeback-files/visa-oip');
  }
  getVisaRdrFiles() {
    return axiosIns.get('/chargeback-files/visa-rdr');
  }
  deleteVisaRdrFile(fileName) {
    return axiosIns.delete(`/chargeback-files/${fileName}/visa-rdr`);
  }
  deleteVisaOipFile(fileName) {
    return axiosIns.delete(`/chargeback-files/${fileName}/visa-oip`);
  }
  getVisaRdrFileUrl(fileName) {
    return axiosIns.get(`/chargeback-files/${fileName}/visa-rdr`);
  }
  getVisaOipFileUrl(fileName) {
    return axiosIns.get(`/chargeback-files/${fileName}/visa-oip`);
  }
}

export default new ChargebackFileService();
