import {
  mdiAccountOutline,
  mdiApplication,
  mdiCashRefund,
  mdiCreditCardSync,
  mdiCloudCheckOutline,
  mdiContactlessPaymentCircleOutline,
  mdiContactsOutline,
  mdiCreditCardSettingsOutline,
  mdiFileDocument,
  mdiFileDocumentOutline,
  mdiFileExcelOutline,
  mdiHomeOutline,
  mdiReceipt,
  mdiViewListOutline,
  mdiCash,
  mdiCashMultiple,
  mdiBank,
  mdiCashUsd,
  mdiPollBoxOutline,
  mdiTextRecognition,
} from '@mdi/js';
import Roles from '@/enums/roles.enum';
import { UserServicesEnum } from '@/enums/user-services.enum';

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'dashboard',
    roles: [Roles.MERCHANT, Roles.USER, Roles.ADMIN],
  },
  {
    title: 'Websites',
    icon: mdiApplication,
    to: 'website-list',
    roles: [Roles.MERCHANT, Roles.USER],
  },
  {
    title: 'Users',
    icon: mdiAccountOutline,
    to: 'manager-list',
    roles: [Roles.MERCHANT],
  },
  {
    title: 'Users',
    icon: mdiAccountOutline,
    roles: [Roles.ADMIN],
    children: [
      {
        title: 'Admins',
        to: 'admin-list',
      },
      {
        title: 'Merchants',
        to: 'merchant-list',
      },
      {
        title: 'Resellers',
        to: 'reseller-list',
      },
    ],
  },
  {
    title: 'Chargeback',
    icon: mdiCashRefund,
    to: 'chargeback',
    services: [UserServicesEnum.CHARGEBACK],
    children: [
      {
        title: 'Events',
        icon: mdiCloudCheckOutline,
        to: 'events-list',
        roles: [Roles.MERCHANT, Roles.USER, Roles.ADMIN],
      },
      {
        title: 'Report',
        icon: mdiFileDocumentOutline,
        to: 'report-list',
        roles: [Roles.ADMIN],
      },
      {
        title: 'Report',
        icon: mdiFileDocumentOutline,
        to: 'merchant-report-list',
        roles: [Roles.MERCHANT, Roles.USER],
      },
      {
        title: 'Files',
        icon: mdiFileExcelOutline,
        roles: [Roles.ADMIN],
        children: [
          {
            title: 'RDR',
            to: 'file-list-visa-rdr',
          },
          {
            title: 'OI',
            to: 'file-list-visa-oi',
          },
        ],
      },
    ],
  },
  {
    title: 'Reports',
    icon: mdiFileDocumentOutline,
    to: 'reseller-report-list',
    roles: [Roles.RESELLER],
  },
  {
    title: 'Analytics',
    icon: mdiPollBoxOutline,
    roles: [Roles.ADMIN],
    children: [
      {
        title: 'Providers Events',
        to: 'chargeback-analytics',
        icon: mdiCloudCheckOutline,
        roles: [Roles.ADMIN],
      },
      {
        title: 'Descriptors',
        to: 'descriptor-analytics',
        icon: mdiTextRecognition,
        roles: [Roles.ADMIN],
      },
      {
        title: 'Website activity',
        icon: mdiFileDocumentOutline,
        to: 'report-website-activity',
        roles: [Roles.ADMIN],
      },
    ]
  },
  {
    title: 'Payments',
    icon: mdiContactlessPaymentCircleOutline,
    to: 'payments',
    services: [UserServicesEnum.PAYMENT],
    children: [
      {
        title: 'Payments',
        icon: mdiCash,
        to: 'payment-events-list',
        roles: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      },
      {
        title: 'Subscriptions',
        icon: mdiCashMultiple,
        to: 'payment-subscriptions-list',
        roles: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      },
      {
        title: 'Acquirers',
        icon: mdiViewListOutline,
        to: 'payment-acquirers-list',
        roles: [Roles.ADMIN],
      },
    ]
  },
  {
    title: 'Billing',
    icon: mdiBank,
    children: [
      {
        title: 'Invoices',
        icon: mdiCashUsd,
        to: 'billing-list',
        roles: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      },
      {
        title: 'Invoices',
        icon: mdiCashUsd,
        to: 'reseller-billing-list',
        roles: [Roles.RESELLER],
      },
      {
        title: 'Reseller Invoices',
        icon: mdiCashUsd,
        to: 'reseller-billing-list',
        roles: [Roles.ADMIN],
      },
      {
        title: 'Manager Invoices',
        icon: mdiCashUsd,
        to: 'manager-billing-list',
        roles: [Roles.ADMIN],
        managerBillingAccess: true,
      },
      {
        title: 'Billing Credentials',
        icon: mdiReceipt,
        to: 'billing-credentials',
        roles: [Roles.ADMIN],
      },
      {
        title: 'Price Config',
        icon: mdiCreditCardSettingsOutline,
        to: 'price-config',
        roles: [Roles.ADMIN],
      },
    ]
  },
  {
    title: 'Applications',
    icon: mdiContactsOutline,
    to: 'applications-list',
    roles: [Roles.ADMIN],
  },
  {
    title: 'Docs',
    icon: mdiFileDocument,
    roles: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
    children: [
      {
        title: 'Payments',
        icon: mdiCreditCardSync,
        children: [
          {
            title: 'Merchants Integration',
            roles: [Roles.ADMIN],
            to: 'docs-payments-merchants-integration',
          },
          {
            title: 'Payments Integration',
            roles: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
            to: 'docs-payments-payments-integration',
          },
          {
            title: 'Subscriptions Integration',
            roles: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
            to: 'docs-payments-subscriptions-integration',
          },
        ]
      },
      {
        title: 'Chargebacks',
        icon: mdiCashRefund,
        roles: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
        children: [
          {
            title: 'Events API',
            to: 'docs-chargebacks-merchanto-events',
          },
          {
            title: 'Events API v2',
            to: 'docs-chargebacks-merchanto-events-v2',
          },
          {
            title: 'Visa OI API',
            to: 'docs-chargebacks-visa-oi',
          },
        ]
      }
    ]
  },
];
