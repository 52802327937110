import axiosIns from '@/plugins/axios';

class WebsiteStripeSettingService {
  getWebsiteStripeSettingByWebsiteId(websiteId) {
    return axiosIns.get(`/website-stripe-settings/website/${websiteId}`);
  }

  saveWebsiteStripeSetting(websiteId, stripeSettings) {
    return axiosIns.post(`/website-stripe-settings/website/${websiteId}`, stripeSettings);
  }

  deleteWebsiteStripeSettingByWebsiteId(websiteId) {
    return axiosIns.delete(`/website-stripe-settings/website/${websiteId}`);
  }
}

export default new WebsiteStripeSettingService();
