<template>
  <layout-blank>
    <slot> </slot>
    <cookie-law v-if="!isAcceptMerchantoCookies && $route.name === 'auth-login'" @accept="acceptCookie()">
      <div slot="message">
        We use cookies to personalise content, to provide social media features and to analyse our traffic.
        <router-link target="_blank" :to="'privacy-cookie'" rel="noopener">Click here for more information</router-link>
      </div>
    </cookie-law>
  </layout-blank>
</template>

<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue';
import CookieLaw from 'vue-cookie-law';

export default {
  components: {
    CookieLaw,
    LayoutBlank,
  },
  computed: {
    isAcceptMerchantoCookies() {
      return this.$cookies.get('isAcceptMerchantoCookies');
    },
  },
  methods: {
    acceptCookie() {
      this.$cookies.set('isAcceptMerchantoCookies', true);
    },
  },
};
</script>
