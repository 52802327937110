import PaymentSubscription from '@/services/api/payments/payment-subscription.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    paymentSubscriptions: [],
    selectedPaymentSubscription: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getPaymentSubscriptions(state) {
      return state.paymentSubscriptions;
    },
    getSelectedPaymentSubscription(state) {
      return state.selectedPaymentSubscription;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchPaymentSubscriptions({ commit }, filters) {
      commit('setIsLoaded', false);
      const response = await PaymentSubscription.getPaymentSubscriptions(filters);
      commit('setIsLoaded', true);
      commit('setPaymentSubscriptions', response.data);

      return response;
    },
    async fetchPaymentSubscriptionById({ commit }, id) {
      commit('setIsLoaded', false);
      const response = await PaymentSubscription.getPaymentSubscriptionById(id);
      commit('setSelectedPaymentSubscription', response.data);
      commit('setIsLoaded', true);
      return response;
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setPaymentSubscriptions(state, paymentSubscriptions) {
      state.paymentSubscriptions = paymentSubscriptions;
    },
    setSelectedPaymentSubscription(state, selectedPaymentSubscription) {
      state.selectedPaymentSubscription = selectedPaymentSubscription;
    },
  },
};
