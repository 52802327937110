import appConfigStoreModule from '@core/@app-config/appConfigStoreModule';
import Vue from 'vue';
import Vuex from 'vuex';

/**
 * Visa OIP
 */
import visaOip from './app/visa-oip.store';

/**
 * Shared
 */
import auth from './app/auth.store';
import user from './app/user.store';
import app from './app/app.store';
import profile from './app/profile.store';
import website from './app/website.store';
import company from './app/company.store';
import websitePaymentDefinition from './app/website-payment-definition.store';
import websitePrice from './app/website-price.store';
import websiteBillingDescriptor from './app/website-billing-descriptor.store';
import websiteMedoroSettings from './app/website-medoro-settings.store';
import websiteStripeSetting from './app/website-stripe-setting.store';
import websitePaymentSettings from './app/website-payment-settings.store';
import application from './app/application.store';
import websiteNotificationEmail from './app/website-notification-email.store';
import billing from './app/billings/billing.store';
import resellerBilling from './app/billings/reseller-billing.store';
import managerBilling from './app/billings/manager-billing.store';
import billingCredentials from './app/billings/billing-credentials.store';

/**
 * Chargebacks
 */
import chargebackEvent from './app/chargebacks/chargeback-event.store';
import chargebackFile from './app/chargebacks/chargeback-file.store';
import chargebackStatistic from './app/chargebacks/chargeback-statistic.store';
import chargebackPayment from './app/chargebacks/chargeback-payment.store';
import chargebackPriceConfig from './app/chargebacks/chargeback-price-config.store';
import chargebackReport from './app/chargebacks/chargeback-report.store';
import chargebackMerchantApi from './app/chargebacks/chargeback-merchant-api.store';

/**
 * Payments
 */
import paymentEvent from './app/payments/payment-event.store';
import paymentSubscription from './app/payments/payment-subscription.store';
import paymentAcquirer from './app/payments/payment-acquirer.store';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    auth,
    user,
    company,
    profile,
    website,
    websiteBillingDescriptor,
    websitePaymentDefinition,
    websiteMedoroSettings,
    websitePrice,
    websiteStripeSetting,
    websitePaymentSettings,
    websiteNotificationEmail,
    visaOip,
    application,
    billing,
    resellerBilling,
    managerBilling,
    chargebackEvent,
    chargebackFile,
    chargebackStatistic,
    chargebackPayment,
    chargebackPriceConfig,
    chargebackReport,
    chargebackMerchantApi,
    paymentEvent,
    paymentAcquirer,
    paymentSubscription,
    billingCredentials,
  },
});

export default store;
