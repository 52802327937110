import PaymentAcquirerService from '@/services/api/payments/payment-acquirer.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    paymentAcquirers: [],
    selectedPaymentAcquirers: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getPaymentAcquirers(state) {
      return state.paymentAcquirers;
    },
    getSelectedPaymentAcquirer(state) {
      return state.selectedPaymentAcquirers;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchPaymentAcquirers({ commit }) {
      commit('setIsLoaded', false);
      const response = await PaymentAcquirerService.getPaymentAcquirers();
      commit('setIsLoaded', true);
      commit('setPaymentAcquirers', response.data);

      return response;
    },
    async storePaymentAcquirer(store, { paymentAcquirer }) {
      await PaymentAcquirerService.createPaymentAcquirer(paymentAcquirer);
    },
    async updatePaymentAcquirer(store, { id, paymentAcquirer }) {
      await PaymentAcquirerService.updatePaymentAcquirer(id, paymentAcquirer);
    },
    async deletePaymentAcquirer(store, id) {
      await PaymentAcquirerService.deletePaymentAcquirer(id);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setPaymentAcquirers(state, paymentAcquirers) {
      state.paymentAcquirers = paymentAcquirers;
    },
    setSelectedPaymentAcquirer(state, paymentAcquirers) {
      state.selectedPaymentAcquirer = paymentAcquirers;
    },
  },
};
