import WebsiteService from '../../services/api/website.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    websites: [],
    selectedWebsite: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getWebsites(state) {
      return state.websites;
    },
    getSelectedWebsite(state) {
      return state.selectedWebsite;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchWebsitesByCompanyId({ commit }, { companyId, query }) {
      commit('setIsLoaded', false);
      const response = await WebsiteService.getWebsitesByCompanyId(companyId, query);
      commit('setIsLoaded', true);
      commit('setWebsites', response.data);

      return response;
    },
    async fetchWebsites({ commit }) {
      commit('setIsLoaded', false);
      const response = await WebsiteService.getWebsites();
      commit('setIsLoaded', true);
      commit('setWebsites', response.data);

      return response;
    },
    async fetchWebsiteById({ commit }, id) {
      commit('setIsLoaded', false);
      const response = await WebsiteService.getWebsiteById(id);
      commit('setIsLoaded', true);
      commit('setSelectedWebsite', response.data);

      return response.data;
    },
    async storeWebsite(store, { merchantId, website }) {
      await WebsiteService.storeWebsite(merchantId, website);
    },
    async updateWebsite(store, { id, website }) {
      await WebsiteService.updateWebsite(id, website);
    },
    async patchStatus(store, { id, status }) {
      await WebsiteService.updateStatus(id, status);
    },
    async deleteWebsite(store, { id }) {
      await WebsiteService.deleteWebsite(id);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setWebsites(state, websites) {
      state.websites = websites;
    },
    setSelectedWebsite(state, website) {
      state.selectedWebsite = website;
    },
  },
};
