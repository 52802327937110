import WebsiteService from '../../services/api/website.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    paymentDefinitions: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getPaymentDefinitions(state) {
      return state.paymentDefinitions;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchPaymentDefinitionsByWebsiteId({ commit }, websiteId) {
      commit('setIsLoaded', false);
      const response = await WebsiteService.getWebsitePaymentDefinitionsByWebsiteId(websiteId);
      commit('setIsLoaded', true);
      commit('setPaymentDefinitions', response.data);

      return response;
    },
    async storePaymentDefinition(store, { websiteId, paymentDefinition }) {
      await WebsiteService.storeWebsitePaymentDefinition(websiteId, paymentDefinition);
    },
    async updatePaymentDefinition(store, { paymentDefinitionId, paymentDefinition }) {
      await WebsiteService.updateWebsitePaymentDefinition(paymentDefinitionId, paymentDefinition);
    },
    async deletePaymentDefinition(store, { paymentDefinitionId }) {
      await WebsiteService.deleteWebsitePaymentDefinition(paymentDefinitionId);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setPaymentDefinitions(state, paymentDefinitions) {
      state.paymentDefinitions = paymentDefinitions;
    },
  },
};
