import Vue from 'vue';
import VueRouter from 'vue-router';
import { getProfile, getTemporaryLoginToken, getToken } from '@/services/jwt.service';
import Roles from '@/enums/roles.enum';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: to => {
      if (getToken()) {
        if (getProfile().role === Roles.RESELLER) {
          return { name: 'reseller-report-list' };
        }
        return { name: 'dashboard' };
      }
      return { name: 'auth-login', query: to.query };
    },
  },
  {
    path: '/profile/me',
    name: 'profile-me',
    component: () => import('@/views/shared/profile/ProfileView'),
    meta: {
      auth: [],
      layout: 'content',
    },
  },
  {
    path: '/files/visa-oi',
    name: 'file-list-visa-oi',
    component: () => import('@/views/chargebacks/chargeback-files/ChargebackVisaOipFiles.vue'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/files/visa-rdr',
    name: 'file-list-visa-rdr',
    component: () => import('@/views/chargebacks/chargeback-files/ChargebackVisaRdrFiles.vue'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/merchant/list',
    name: 'merchant-list',
    component: () => import('@/views/shared/users/merchant/MerchantList.vue'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/merchant/view/:id',
    name: 'merchant-view',
    component: () => import('@/views/shared/users/merchant/MerchantView.vue'),
    meta: {
      auth: [Roles.ADMIN, Roles.MERCHANT],
      layout: 'content',
    },
  },
  {
    path: '/merchant/view/:id/website/:websiteId',
    name: 'website-view',
    component: () => import('@/views/shared/websites/website-profile/WebsiteView.vue'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/merchant/view/:id/website/:websiteId/event-pricing',
    name: 'website-event-pricing',
    component: () => import('@/views/shared/websites/website-profile/WebsiteEventPricing.vue'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/merchant/view/:id/websites/:websiteId/payment-settings',
    name: 'website-payment-settings',
    component: () => import('@/views/shared/users/merchant/merchant-website-payment-settings/MerchantWebsitePaymentSettings'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/users',
    name: 'manager-list',
    component: () => import('@/views/shared/users/merchant/_partials/MerchantManagerList.vue'),
    meta: {
      auth: [Roles.MERCHANT],
      layout: 'content',
    },
  },
  {
    path: '/reseller/list',
    name: 'reseller-list',
    component: () => import('@/views/shared/users/reseller/ResellerList.vue'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/reseller/view/:id',
    name: 'reseller-view',
    component: () => import('@/views/shared/users/reseller/ResellerView.vue'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/admin/list',
    name: 'admin-list',
    component: () => import('@/views/shared/users/admin/AdminList.vue'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/admin/view/:id',
    name: 'admin-view',
    component: () => import('@/views/shared/users/admin/AdminView.vue'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/websites',
    name: 'website-list',
    component: () => import('@/views/shared/websites/WebsiteList'),
    meta: {
      auth: [Roles.MERCHANT, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/billing',
    name: 'billing-list',
    component: () => import('@/views/shared/billings/billing-list/BillingList'),
    meta: {
      auth: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/reseller-billing',
    name: 'reseller-billing-list',
    component: () => import('@/views/shared/reseller-billings/ResellerBillingList'),
    meta: {
      auth: [Roles.RESELLER, Roles.ADMIN],
      layout: 'content',
    },
  },

  {
    path: '/manager-billing',
    name: 'manager-billing-list',
    component: () => import('@/views/shared/manager-billings/ManagerBillingList'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
      managerBillingAccess: true,
    },
  },

  {
    path: '/billing/:id',
    name: 'billing-view',
    component: () => import('@/views/shared/billings/billing-list/BillingView'),
    meta: {
      auth: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/reseller-billing/:id',
    name: 'reseller-billing-view',
    component: () => import('@/views/shared/reseller-billings/ResellerBillingView'),
    meta: {
      auth: [Roles.RESELLER, Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/manager-billing/:id',
    name: 'manager-billing-view',
    component: () => import('@/views/shared/manager-billings/ManagerBillingView'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
      managerBillingAccess: true,
    },
  },
  {
    path: '/billing-credentials',
    name: 'billing-credentials',
    component: () => import('@/views/shared/billings/billing-credentials/BillingCredentials'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/applications',
    name: 'applications-list',
    component: () => import('@/views/shared/applications/ApplicationList'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/applications/:id',
    name: 'applications-view',
    component: () => import('@/views/shared/applications/ApplicationView'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/price-config',
    name: 'price-config',
    component: () => import('@/views/chargebacks/chargeback-price-configs/ChargebackPriceConfigs'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/chargeback/report',
    name: 'report-list',
    component: () => import('@/views/chargebacks/chargeback-reports/ChargebackReportsList'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/chargeback/merchant/report',
    name: 'merchant-report-list',
    component: () => import('@/views/chargebacks/chargeback-reports/ChargebackMerchantReportsList'),
    meta: {
      auth: [Roles.MERCHANT, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/chargeback/reseller/report',
    name: 'reseller-report-list',
    component: () => import('@/views/chargebacks/chargeback-reports/ChargebackResellerReportsList.vue'),
    meta: {
      auth: [Roles.RESELLER],
      layout: 'content',
    },
  },
  {
    path: '/analytics/website-activity',
    name: 'report-website-activity',
    component: () => import('@/views/chargebacks/chargeback-reports/ChargebackReportsWebsiteActivity'),
    meta: {
      auth: [ Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/analytics/chargeback-analytics',
    name: 'chargeback-analytics',
    component: () => import('@/views/analytics/chargeback-analytics/ChargebackAnalytics'),
    meta: {
      auth: [ Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/analytics/descriptor-analytics',
    name: 'descriptor-analytics',
    component: () => import('@/views/analytics/descriptor-analytics/DescriptorAnalytics'),
    meta: {
      auth: [ Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/chargeback/events',
    name: 'events-list',
    component: () => import('@/views/chargebacks/chargeback-events/ChargebackEventsList'),
    meta: {
      auth: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/chargeback/events/:id',
    name: 'event-view',
    component: () => import('@/views/chargebacks/chargeback-events/ChargebackEventView'),
    meta: {
      auth: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/docs/chargebacks/merchanto-events',
    name: 'docs-chargebacks-merchanto-events',
    component: () => import('@/views/docs/chargebacks/ChargebackApiMerchantoEvents'),
    meta: {
      auth: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/docs/chargebacks/v2/merchanto-events',
    name: 'docs-chargebacks-merchanto-events-v2',
    component: () => import('@/views/docs/chargebacks/ChargebackApiMerchantoEventsV2'),
    meta: {
      auth: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/docs/chargebacks/visa-oi',
    name: 'docs-chargebacks-visa-oi',
    component: () => import('@/views/docs/chargebacks/ChargebackApiVisaOi'),
    meta: {
      auth: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/payments/events',
    name: 'payment-events-list',
    component: () => import('@/views/payments/payment-events/PaymentEventsList'),
    meta: {
      auth: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/payments/acquirers',
    name: 'payment-acquirers-list',
    component: () => import('@/views/payments/payment-acquirers/PaymentAcquirerList'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/payments/events/:id',
    name: 'payment-event-view',
    component: () => import('@/views/payments/payment-events/PaymentEventView'),
    meta: {
      auth: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/payments/subscriptions',
    name: 'payment-subscriptions-list',
    component: () => import('@/views/payments/payment-subscriptions/PaymentSubscriptionsList'),
    meta: {
      auth: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/payments/subscriptions/:id',
    name: 'payment-subscription-view',
    component: () => import('@/views/payments/payment-subscriptions/PaymentSubscriptionView'),
    meta: {
      auth: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/docs/payments/merchants',
    name: 'docs-payments-merchants-integration',
    component: () => import('@/views/docs/merchants/MerchantsIntegration.vue'),
    meta: {
      auth: [Roles.ADMIN],
      layout: 'content',
    },
  },
  {
    path: '/docs/payments/payments',
    name: 'docs-payments-payments-integration',
    component: () => import('@/views/docs/payments/PaymentsIntegration.vue'),
    meta: {
      auth: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/docs/payments/subscriptions',
    name: 'docs-payments-subscriptions-integration',
    component: () => import('@/views/docs/subscriptions/SubscriptionsIntegration.vue'),
    meta: {
      auth: [Roles.MERCHANT, Roles.ADMIN, Roles.USER],
      layout: 'content',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/policy/Privacy.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/term-of-use',
    name: 'term-of-use',
    component: () => import('@/views/policy/TermOfUse.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/privacy-cookie',
    name: 'privacy-cookie',
    component: () => import('@/views/policy/Cookies.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/reset-password/token/:token',
    name: 'auth-reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboards/Dashboard.vue'),
    meta: {
      auth: [],
      layout: 'content',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const { auth, resource, managerBillingAccess } = to.meta;

  const profile = getProfile();

  if (auth) {
    if (!store.getters['auth/isLoggedIn']) {
      return next({ path: '/login', query: { returnUrl: to.path } });
    }

    if (auth.length && !auth.includes(profile.role)) {
      return next({ path: '/page-not-found' });
    }

    if (managerBillingAccess && !profile.managerBillingAccess) {
      return next({ path: '/page-not-found' });
    }

  } else {
    if (resource && resource === 'Public') {
      return next();
    } else {
      if (store.getters['auth/isLoggedIn']) {
        return next({ path: '/' });
      }
    }
  }

  if (!profile.is2faEnabled) {
    if (getTemporaryLoginToken()) {
      return next();
    }
    if (to.path === '/profile/me') {
      return next();
    }
    return next({ path: '/profile/me' });
  }

  return next();
});

export default router;
