import axiosIns from '@axios';

class ChargebackStatisticService {
  getEventStatistics(filters) {
    Object.keys(filters).forEach(k => filters[k] == null && delete filters[k]);
    const query = new URLSearchParams(filters);

    return axiosIns.get(`/chargeback-statistics/chargeback-events?${query}`);
  }
}

export default new ChargebackStatisticService();
