import WebsiteService from '../../services/api/website.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    websiteBillingDescriptors: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getWebsiteBillingDescriptors(state) {
      return state.websiteBillingDescriptors;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchWebsiteBillingDescriptorsByWebsiteId({ commit }, websiteId) {
      commit('setIsLoaded', false);
      const response = await WebsiteService.getWebsiteBillingDescriptorsByWebsiteId(websiteId);
      commit('setIsLoaded', true);
      commit('setWebsiteBillingDescriptors', response.data);

      return response;
    },
    async storeWebsiteBillingDescriptor(store, { websiteId, websiteBillingDescriptor }) {
      await WebsiteService.storeWebsiteBillingDescriptor(websiteId, websiteBillingDescriptor);
    },
    async updateWebsiteBillingDescriptor(store, { websiteBillingDescriptorId, websiteBillingDescriptor }) {
      await WebsiteService.updateWebsiteBillingDescriptor(websiteBillingDescriptorId, websiteBillingDescriptor);
    },
    async deleteWebsiteBillingDescriptor(store, { websiteBillingDescriptorId }) {
      await WebsiteService.deleteWebsiteBillingDescriptor(websiteBillingDescriptorId);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setWebsiteBillingDescriptors(state, websiteBillingDescriptors) {
      state.websiteBillingDescriptors = websiteBillingDescriptors;
    },
  },
};
