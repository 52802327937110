import WebsiteMedoroSettingsService from '../../services/api/website-medoro-settings.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    websiteMedoroSettings: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getWebsiteMedoroSettings(state) {
      return state.websiteMedoroSettings;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchWebsiteMedoroSettingsByWebsiteId({ commit }, websiteId) {
      commit('setIsLoaded', false);
      const response = await WebsiteMedoroSettingsService.getWebsiteMedoroSettingByWebsiteId(websiteId);
      commit('setIsLoaded', true);
      commit('setWebsiteMedoroSettings', response.data);

      return response.data;
    },
    async saveWebsiteMedoroSettings(store, { websiteId, settings, gatewayKey }) {
      const response = await WebsiteMedoroSettingsService.saveWebsiteMedoroSettings(websiteId, settings, gatewayKey);
      store.commit('setWebsiteMedoroSettings', response.data);
      return response.data;
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setWebsiteMedoroSettings(state, medoroSettings) {
      state.medoroSettings = medoroSettings;
    },
  },
};
