import PaymentEventService from '@/services/api/payments/payment-event.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    paymentEvents: [],
    selectedPaymentEvent: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getPaymentEvents(state) {
      return state.paymentEvents;
    },
    getSelectedPaymentEvent(state) {
      return state.selectedPaymentEvent;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchPaymentEvents({ commit }, filters) {
      commit('setIsLoaded', false);
      const response = await PaymentEventService.getPaymentEvents(filters);
      commit('setIsLoaded', true);
      commit('setPaymentEvents', response.data);

      return response;
    },
    async fetchPaymentEventsById({ commit }, id) {
      commit('setIsLoaded', false);
      const response = await PaymentEventService.getPaymentEventById(id);
      commit('setSelectedPaymentEvent', response.data);
      commit('setIsLoaded', true);
      return response;
    },
    async refundPaymentEventByPaymentId(store, refundData) {
      await PaymentEventService.refundPaymentEventByPaymentId(refundData);
    },
    async getPaymentForm(store, publicKey) {
      return PaymentEventService.getPaymentForm(publicKey);
    },
    async processPayment(store, payload) {
      return PaymentEventService.processPayment(payload);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setPaymentEvents(state, paymentEvents) {
      state.paymentEvents = paymentEvents;
    },
    setSelectedPaymentEvent(state, paymentEvent) {
      state.selectedPaymentEvent = paymentEvent;
    },
  },
};
