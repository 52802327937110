import axiosIns from '@/plugins/axios';

class WebsitePricesService {
  getByWebsiteId(websiteId) {
    return axiosIns.get(`/website-prices/website/${websiteId}`);
  }

  update(websiteId, price) {
    return axiosIns.put(`/website-prices/website/${websiteId}`, price);
  }

  recalculateChargebackPrices() {
    return axiosIns.post('/website-prices/recalculate-chargeback-prices');
  }
}

export default new WebsitePricesService();
