import BillingService from '../../../services/api/billing.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    billingCredentials: {},
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getBillingCredentials(state) {
      return state.billingCredentials;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchBillingCredentials({ commit }, payload) {
      commit('setIsLoaded', false);
      const response = await BillingService.getBillingCredentials(payload);
      commit('setBillingCredentials', response.data);
      commit('setIsLoaded', true);

      return response.data;
    },
    async fetchDefaultBillingCredentials(store, currency) {
      return BillingService.getDefaultBillingCredentials(currency);
    },
    async saveBillingCredentials(store, payload) {
      await BillingService.saveBillingCredentials(payload);
    },
    async updateBillingCredentials(store, { id, billingCredentials }) {
      await BillingService.updateBillingCredentials(id, billingCredentials);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setBillingCredentials(state, billingCredentials) {
      state.billingCredentials = billingCredentials;
    },
  },
};
