import ChargebackFileService from '../../../services/api/chargebacks/chargeback-file.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    files: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getFiles(state) {
      return state.files;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchVisaRdrFiles({ commit }) {
      commit('setIsLoaded', false);
      const response = await ChargebackFileService.getVisaRdrFiles();
      commit('setIsLoaded', true);
      commit('setFiles', response.data);

      return response;
    },
    async fetchVisaOipFiles({ commit }) {
      commit('setIsLoaded', false);
      const response = await ChargebackFileService.getVisaOipFiles();
      commit('setIsLoaded', true);
      commit('setFiles', response.data);

      return response;
    },
    async deleteVisaRdrFile(store, fileName) {
      await ChargebackFileService.deleteVisaRdrFile(fileName);
    },
    async deleteVisaOipFile(store, fileName) {
      await ChargebackFileService.deleteVisaOipFile(fileName);
    },
    async getVisaRdrFileUrl(store, fileName) {
      const response = await ChargebackFileService.getVisaRdrFileUrl(fileName);
      return response.data;
    },
    async getVisaOipFileUrl(store, fileName) {
      const response = await ChargebackFileService.getVisaOipFileUrl(fileName);
      return response.data;
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setFiles(state, files) {
      state.files = files;
    },
  },
};
