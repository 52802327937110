import Vue from 'vue';

export default function handleError(error) {
  const defaultErrorMessage = 'Undefined error. Please try again later.';
  if (error.response && error.response.status) {
    if (500 === error.response.status) {
      return Vue.notify({
        type: 'error',
        text: 'Server error. Please try again later.',
      });
    }

    if (error.response.data) {
      if (!error.response.data.field) {
        if (error.response.data.message.length && error.response.data.message.length > 0) {
          if (Array.isArray(error.response.data.message)) {
            error.response.data.message.forEach(message => {
              Vue.notify({
                type: 'error',
                text: `${message}`,
              });
            });
          } else {
            Vue.notify({
              type: 'error',
              text: `${error.response.data.message}`,
            });
          }
        } else {
          Vue.notify({
            type: 'error',
            text: `${error.response.data.message}`,
          });
        }

        return error.response.data.message;
      }
      return { [error.response.data.field]: error.response.data.message };
    }
  }

  return defaultErrorMessage;
}
