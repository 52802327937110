<template>
<!--  <component :is="'blank'" :class="`skin-variant&#45;&#45;${appSkinVariant}`">-->
<!--    <under-maintenance></under-maintenance>-->
<!--  </component>-->
  <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`">
    <vue-confirm-dialog></vue-confirm-dialog>
    <transition :name="appRouteTransition" mode="out-in" appear>
      <router-view :key="$route.path"></router-view>
    </transition>
    <TwoFaDialog></TwoFaDialog>
    <notifications position="top center" width="auto" />
  </component>
</template>

<script>
import { computed } from '@vue/composition-api';
import useAppConfig from '@core/@app-config/useAppConfig';
import { useRouter } from '@core/utils';
import { useLayout } from '@core/layouts/composable/useLayout';

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue';
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue';

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh';
import UnderMaintenance from '@/views/UnderMaintenance.vue';
import TwoFaDialog from '@/views/shared/2fa/TwoFaDialog.vue';

export default {
  components: {
    TwoFaDialog,
    UnderMaintenance,
    LayoutContentVerticalNav,
    LayoutBlank,
  },
  setup() {
    const { route } = useRouter();
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig();

    const { handleBreakpointLayoutSwitch } = useLayout();
    handleBreakpointLayoutSwitch();

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank';
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`;

      return null;
    });

    useDynamicVh();

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    };
  },
};
</script>
