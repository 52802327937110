import { mdiCheckboxBlankCircleOutline } from '@mdi/js';

const themeConfig = {
  app: {
    name: 'Merchanto',
    logo: require('@/assets/images/svg/logo.svg'),
    isDark: false,
    isRtl: false,
    contentLayoutNav: 'vertical',
    routeTransition: 'scroll-x-transition',
    skinVariant: 'default',
    contentWidth: 'boxed',
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: false,
    verticalMenuAccordion: true,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    type: 'fixed',
    isBlurred: true,
  },
  footer: {
    type: 'static',
  },
  themes: {
    light: {
      primary: '#3c47ad',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
    },
    dark: {
      primary: '#3c47ad',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
    },
  },
};

export default themeConfig;
