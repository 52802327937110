import ChargebackReportService from '@/services/api/chargebacks/chargeback-report.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    report: [],
    websiteActivityReport: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getReport(state) {
      return state.report;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchReport({ commit }, filters) {
      commit('setIsLoaded', false);
      const response = await ChargebackReportService.getReport(filters);
      commit('setIsLoaded', true);
      commit('setReport', response.data);

      return response;
    },

    async fetchWebsiteActivity({ commit }, filters) {
      commit('setIsLoaded', false);
      const response = await ChargebackReportService.getWebsiteActivity(filters);
      commit('setIsLoaded', true);
      commit('setWebsiteActivityReport', response.data);

      return response;
    }
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setReport(state, report) {
      state.report = report;
    },
    setWebsiteActivityReport(state, websiteActivityReport) {
      state.websiteActivityReport = websiteActivityReport;
    },
  },
};
