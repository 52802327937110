import {
  setProfile,
  getProfile,
} from '@/services/jwt.service';
import ProfileService from '@/services/api/profile.service';
import Vue from 'vue';

const getDefaultState = () => {
  return {
    profile: getProfile(),
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    profile: state => state.profile,
  },
  actions: {
    async profile({ commit }) {
      const response = await ProfileService.me();
      await commit('setProfile', response.data);
      return response.data;
    },

    async verifyPhone() {
      return await ProfileService.verifyPhone();
    },

    async generateAuthenticatorQr() {
      return (await ProfileService.generateAuthenticatorQr()).data;
    },

    async patchPhone(store, phone) {
      return await ProfileService.patchPhone(phone);
    },

    async pathTwoFaType(store, twoFaType) {
      return await ProfileService.patchTwoFaType(twoFaType);
    },

    async verifyCode(store, code) {
      await ProfileService.validatePhoneVerification(code);
      return {
        isPhoneVerified: true,
      };
    },

    async enable2fa() {
      try {
        await ProfileService.enable2fa();
        return {
          is2faEnabled: true,
        };
      } catch (e) {
        Vue.notify({
          type: 'error',
          title: 'Phone verification',
          text: e.response.data.message,
          duration: 5000,
        });
      }
    },

    async send2fa(store, email) {
      try {
        await ProfileService.send2fa(email);
      } catch (e) {
        Vue.notify({
          type: 'error',
          title: 'Cannot send 2fa code',
          text: e.response.data.message,
          duration: 5000,
        });
      }
    },

    async check2fa(store, code) {
      await ProfileService.check2fa(code);
    },
  },

  mutations: {
    setProfile(state, profile) {
      state.profile = profile;
      setProfile(profile);
    },
  },
};
