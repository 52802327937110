import axiosIns from '@axios';

class PaymentEventService {
  getPaymentEvents(filters) {
    Object.keys(filters).forEach(k => filters[k] == null && delete filters[k]);
    const query = new URLSearchParams(filters);

    return axiosIns.get(`/payment-events?${query}`);
  }

  getPaymentEventById(id) {
    return axiosIns.get(`/payment-events/${id}`);
  }

  refundPaymentEventByPaymentId(refundData) {
    return axiosIns.post('/payment-events/refund', refundData);
  }

  getPaymentForm(publicKey) {
    return axiosIns.get(`/payment-events/create/${publicKey}`);
  }

  processPayment(payload) {
    return axiosIns.post('/payment-events/process', payload);
  }
}

export default new PaymentEventService();
