import WebsiteService from '../../services/api/website.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    websiteNotificationEmails: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getWebsiteNotificationEmails(state) {
      return state.websiteNotificationEmails;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchWebsiteNotificationEmailsByWebsiteId({ commit }, websiteId) {
      commit('setIsLoaded', false);
      const response = await WebsiteService.getWebsiteNotificationEmailsByWebsiteId(websiteId);
      commit('setIsLoaded', true);
      commit('setWebsiteNotificationEmails', response.data);

      return response;
    },
    async storeWebsiteNotificationEmail(store, { websiteId, websiteNotificationEmail }) {
      await WebsiteService.storeWebsiteNotificationEmail(websiteId, websiteNotificationEmail);
    },
    async updateWebsiteNotificationEmail(store, { websiteNotificationEmailId, websiteNotificationEmail }) {
      await WebsiteService.updateWebsiteNotificationEmail(websiteNotificationEmailId, websiteNotificationEmail);
    },
    async deleteWebsiteNotificationEmail(store, { websiteNotificationEmailId }) {
      await WebsiteService.deleteWebsiteNotificationEmail(websiteNotificationEmailId);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setWebsiteNotificationEmails(state, websiteNotificationEmails) {
      state.websiteNotificationEmails = websiteNotificationEmails;
    },
  },
};
