<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ toggleVerticalNavMenuActive }">
      <div class="navbar-content-container">
        <!-- Left Content: Search -->
        <div class="d-flex align-center navbar-content-button">
          <v-icon v-if="$vuetify.breakpoint.mdAndDown" class="me-3" @click="toggleVerticalNavMenuActive">
            {{ icons.mdiMenu }}
          </v-icon>
        </div>

        <div class="d-flex align-center right-row">
          <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} Merchanto, All rights Reserved</span>
        <div class="policy-links d-flex justify-space-between">
          <a :href="'/privacy-cookie'" target="_blank" rel="noopener">Cookie</a>
          <a :href="'/privacy'" target="_blank" rel="noopener">Privacy</a>
          <a :href="'/term-of-use'" target="_blank" rel="noopener">Term Of Use</a>
        </div>
      </div>
    </template>

    <div v-if="isLoggedInAsMerchant" class="logged-in-as-merchant-banner">
      <p class="white--text text-h6 ma-0 pa-0 text-center">
        Logged in as {{ loggedInAsMerchantName }}. Remained time:
        <span class="logged-in-as-merchant-banner__remained-time" :class="{ animated: remainedTime <= 30000 }">{{
          remainedTime | formatRemainedTime
        }}</span>
      </p>
    </div>
  </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue';
import navMenuItems from '@/router/vertical';
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue';
import AppBarUserMenu from '@core/layouts/components/app-bar/AppBarUserMenu.vue';
import { mdiMenu, mdiHeartOutline } from '@mdi/js';
import dayjs from 'dayjs';

export default {
  components: {
    LayoutContentVerticalNav,
    // App Bar Components
    AppBarThemeSwitcher,
    AppBarUserMenu,
  },
  filters: {
    formatRemainedTime: remainedTime => {
      if (remainedTime) {
        return dayjs(remainedTime).format('mm:ss');
      }
    },
  },
  setup() {
    return {
      navMenuItems,

      icons: {
        mdiMenu,
        mdiHeartOutline,
      },
    };
  },
  data: () => ({
    remainedTime: undefined,
    remainedTimeInterval: undefined,
    loggedInAsMerchantName: undefined,
  }),
  computed: {
    isLoggedInAsMerchant() {
      return this.$store.state.auth.loggedInAsMerchant;
    },
  },
  watch: {
    isLoggedInAsMerchant(loggedIn) {
      if (loggedIn) {
        this.assignLoggedInAsMerchantData();
      }
    }
  },
  methods: {
    async setRemainedTime() {
      this.remainedTime = dayjs(this.$store.state.auth.temporaryLoginTokenExpirationDate) - dayjs();

      if (this.remainedTime <= 0) {
        clearInterval(this.remainedTimeInterval);
        await this.$store.dispatch('auth/merchantLogout');
        window.close();
      }
    },
    assignLoggedInAsMerchantData() {
      this.loggedInAsMerchantName = this.$store.state.auth.profile.fullName;
      setInterval(() => {
        this.setRemainedTime();
      }, 1000);
    }
  },
  created() {
    if (this.isLoggedInAsMerchant) {
      this.assignLoggedInAsMerchantData();
    }
  }
};
</script>

<style lang="scss" scoped>
.policy-links {
  width: 235px;
  > a {
    text-decoration: none;
  }
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.logged-in-as-merchant-banner {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ef5350;
  padding: 20px;
  z-index: 8;

  &__remained-time {
    display: inline-block;
    &.animated {
      animation: pulse 1s infinite;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
