import ChargebackPaymentService from '@/services/api/chargebacks/chargeback-payment.service';

export default {
  namespaced: true,
  state: {},
  actions: {
    async createPayment(store, items) {
      const response = await ChargebackPaymentService.createPayment(items);
      return response.data;
    },
  },
};
