import axiosIns from '@/plugins/axios';

class WebsitePaymentSettingsService {
  getWebsitePaymentSettingsByWebsiteId(websiteId) {
    return axiosIns.get(`/website-payment-settings/website/${websiteId}`);
  }

  saveWebsitePaymentSettings(websiteId, paymentSettings) {
    return axiosIns.post(`/website-payment-settings/website/${websiteId}`, paymentSettings);
  }

  generateApiKeys(websiteId) {
    return axiosIns.patch(`/website-payment-settings/website/${websiteId}/generate-api-keys`);
  }

  generateRSAKeys(websiteId) {
    return axiosIns.get(`/website-payment-settings/website/${websiteId}/generate-rsa-keys`);
  }
}

export default new WebsitePaymentSettingsService();
