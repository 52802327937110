import BillingService from '../../../services/api/billing.service';
import Vue from 'vue';

const getDefaultState = () => {
  return {
    isLoaded: false,
    billings: [],
    selectedBilling: null,
    billingHistory: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getBillings(state) {
      return state.billings;
    },
    getSelectedBilling(state) {
      return state.selectedBilling;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchBillings({ commit }, filters) {
      commit('setIsLoaded', false);
      const response = await BillingService.getBillings(filters);
      commit('setIsLoaded', true);
      commit('setBillings', response.data);

      return response;
    },
    async fetchBillingsByMerchantId({ commit }, { id, filters }) {
      commit('setIsLoaded', false);
      const response = await BillingService.getBillingsByMerchantId(id, filters);
      commit('setIsLoaded', true);
      commit('setBillings', response.data);

      return response;
    },
    async fetchBillingById({ commit }, { id }) {
      commit('setIsLoaded', false);
      const response = await BillingService.getBillingById(id);
      commit('setSelectedBilling', response.data);
      commit('setIsLoaded', true);
      return response;
    },
    async getHistory({ commit }, { id }) {
      commit('setIsLoaded', false);
      const response = await BillingService.getHistory(id);
      commit('setBillingHistory', response.data);
      commit('setIsLoaded', true);
      return response;
    },
    async saveCustomCorrection(store, { id, customCorrection }) {
      await BillingService.customCorrection(id, customCorrection);
    },
    async bulkPatchStatus(store, { ids, status }) {
      await BillingService.bulkPatchStatus(ids, status);
    },
    async sendInvoices(store, { ids }) {
      await BillingService.sendInvoices(ids);
    },
    async deleteInvoices(store, { ids }) {
      await BillingService.deleteInvoices(ids);
    },
    async downloadInvoice(store, { id }) {
      return await BillingService.downloadInvoice(id);
    },
    async downloadManagerInvoice(store, { id }) {
      return await BillingService.downloadManagerInvoice(id);
    },
    async downloadReceipt(store, { id }) {
      return await BillingService.downloadReceipt(id);
    },
    async addNewInvoice(store, invoicePayload) {
      const result = await BillingService.addNewInvoice(invoicePayload);
      const skipped = Object.values(result).filter((item) => item.skiped === true).length;
      const created = Object.values(result).filter((item) => item.skiped === false).length;
      if (skipped) {
        Vue.notify({
          type: 'error',
          title: 'Invoices',
          text: `Requested ${skipped} of total ${skipped+created} invoices was already created`,
        });
      } else if (result.skiped) {
        Vue.notify({
          type: 'error',
          title: 'Invoices',
          text: 'Requested invoice is already created',
        });
      }
    },
    async deleteInvoice(store, { id }) {
      await BillingService.deleteInvoice(id);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setBillings(state, billings) {
      state.billings = billings;
    },
    setSelectedBilling(state, billing) {
      state.selectedBilling = billing;
    },
    setBillingHistory(state, billingHistory) {
      state.billingHistory = billingHistory;
    },
  },
};
