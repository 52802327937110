import axiosIns from '@axios';

class VisaOipService {
  putVisaOipSettingsByWebsiteId(websiteId, visaOiSettings) {
    return axiosIns.put(`/chargeback/visa-oi-settings/${websiteId}`, visaOiSettings);
  }

  getVisaOipSettingsByWebsiteId(websiteId) {
    return axiosIns.get(`/chargeback/visa-oi-settings/${websiteId}`);
  }
}

export default new VisaOipService();
