import ManagerBillingService from '@/services/api/manager-billing.service';

const getDefaultState = () => {
  return {
    isLoaded: false,
    managerBillings: [],
    selectedManagerBilling: null,
    billingHistory: [],
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getManagerBillings(state) {
      return state.managerBillings;
    },
    getSelectedManagerBilling(state) {
      return state.selectedManagerBilling;
    },
    isLoaded(state) {
      return state.isLoaded;
    },
  },
  actions: {
    async fetchAll({ commit }, filters) {
      commit('setIsLoaded', false);
      const response = await ManagerBillingService.getAll(filters);
      commit('setIsLoaded', true);
      commit('setManagerBillings', response.data);

      return response;
    },
    async fetchById({ commit }, { id }) {
      commit('setIsLoaded', false);
      const response = await ManagerBillingService.getById(id);
      commit('setSelectedManagerBilling', response.data);
      commit('setIsLoaded', true);
      return response;
    },
    async bulkPatchStatus(store, { ids, status }) {
      await ManagerBillingService.bulkPatchStatus(ids, status);
    },
    async deleteInvoices(store, { ids }) {
      await ManagerBillingService.deleteInvoices(ids);
    },
    async deleteInvoice(store, { id }) {
      await ManagerBillingService.deleteInvoice(id);
    },
  },
  mutations: {
    setIsLoaded(state, isLoaded) {
      state.isLoaded = isLoaded;
    },
    setManagerBillings(state, managerBillings) {
      state.managerBillings = managerBillings;
    },
    setSelectedManagerBilling(state, managerBillings) {
      state.selectedManagerBilling = managerBillings;
    },
  },
};
