import axiosIns from '@/plugins/axios';

class UserService {
  getAll(filters) {
    Object.keys(filters).forEach(k => filters[k] == null && delete filters[k]);
    const query = new URLSearchParams(filters);

    return axiosIns.get(`/users?${query}`);
  }

  getAdmins() {
    return axiosIns.get('/users/admins');
  }

  getUsersByCompanyId(companyId) {
    return axiosIns.get(`/users/company/${companyId}`);
  }

  store(user) {
    return axiosIns.post('/users', user);
  }

  update(user) {
    return axiosIns.put(`/users/${user.id}`, user);
  }

  getById(id) {
    return axiosIns.get(`/users/${id}`);
  }
  setNewPassword(id, passwordData) {
    return axiosIns.post(`/users/${id}/set-new-password`, passwordData);
  }

  updateStatus(id, status) {
    return axiosIns.patch(`/users/${id}/status`, status);
  }

  reset2fa(id) {
    return axiosIns.patch(`/users/${id}/reset-2fa`);
  }

  delete(id) {
    return axiosIns.delete(`/users/${id}`);
  }

  resendPasswordChangeRequest(id) {
    return axiosIns.post(`/users/${id}/resend-password-change-request`);
  }

  changeComment(id, comment) {
    return axiosIns.patch(`/users/${id}/comment`, { comment });
  }

  assignServices(id, services) {
    return axiosIns.patch(`/users/${id}/assign-services`, { services });
  }

  assignReseller(id, resellerId) {
    return axiosIns.patch(`/users/${id}/assign-reseller`, { resellerId });
  }

  assignManager(id, managerId) {
    return axiosIns.patch(`/users/${id}/assign-manager`, { managerId });
  }

  setMainMerchant(id) {
    return axiosIns.patch(`/users/${id}/change-main-merchant`);
  }
}

export default new UserService();
