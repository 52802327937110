import axiosIns from '@axios';

class ChargebackEventService {
  getEvents(filters) {
    Object.keys(filters).forEach(k => filters[k] == null && delete filters[k]);
    const query = new URLSearchParams(filters);

    return axiosIns.get(`/chargeback-events?${query}`);
  }

  exportEvents(filters) {
    Object.keys(filters).forEach(k => filters[k] == null && delete filters[k]);
    const query = new URLSearchParams(filters);

    return axiosIns
      .get(`/chargeback-events/export/file?${query}`, {
        responseType: 'blob',
      })
      .then(response => {
        this.downloadFile(response.data, 'events.csv', 'text/csv');
      });
  }

  generateFakeEvents(fakeEvents) {
    return axiosIns
      .post('/chargeback-events/generate-events', fakeEvents);
  }

  getEventsByMerchantId(merchantId, filters) {
    Object.keys(filters).forEach(k => filters[k] == null && delete filters[k]);
    const query = new URLSearchParams(filters);

    return axiosIns.get(`/chargeback-events/merchant/${merchantId}?${query}`);
  }

  getEventById(id) {
    return axiosIns.get(`/chargeback-events/${id}`);
  }

  getEventLogsById(id) {
    return axiosIns.get(`/chargeback-logs/chargeback-event/${id}`);
  }

  updateStatus(id, status, refunded, comment, reason) {
    return axiosIns.patch(`/chargeback-events/${id}/status`, { status, refunded, comment, reason });
  }

  isManualUpdated(id, isManualUpdated) {
    return axiosIns.patch(`/chargeback-events/${id}/manual-updated`, { isManualUpdated });
  }

  uploadFile(formData) {
    return axiosIns.post('/chargeback-events/file-upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  bulkUpdateEventsStatus(data) {
    return axiosIns.patch('/chargeback-events/bulk-update-status', data);
  }

  downloadReport(name) {
    return axiosIns
      .get(`/chargeback-events/bulk-update-status/download-report/${name}`, {
        responseType: 'blob',
      })
      .then(response => {
        this.downloadFile(response.data, 'report.csv', 'text/csv');
      });
  }

  eventAnalytics(formData) {
    return axiosIns.post('/chargeback-events/event-analytics', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  descriptorAnalytics(formData) {
    return axiosIns.post('/website-descriptors/descriptor-analytics', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  markKnown(payload) {
    const descriptor = payload.matched_descriptor
      ? payload.matched_descriptor
      : payload.match_criterion;

    return axiosIns.post('/website-descriptors/mark-known',  {
      descriptor: descriptor
    });
  }

  downloadFile(data, fileName, type) {
    //Fix for Safari downloading csv
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(
      new Blob([data], { type })
    );
    a.setAttribute('download', fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }
}

export default new ChargebackEventService();
