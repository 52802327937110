import axiosIns from '@/plugins/axios';

class WebsiteService {
  storeWebsite(merchantId, website) {
    return axiosIns.post(`/websites/merchant/${merchantId}`, website);
  }

  updateWebsite(id, website) {
    return axiosIns.put(`/websites/${id}`, website);
  }

  getWebsitesByCompanyId(companyId, query) {
    return axiosIns.get(`/websites/company/${companyId}?q=${query}`);
  }

  getWebsites() {
    return axiosIns.get('/websites');
  }

  getWebsiteById(id) {
    return axiosIns.get(`/websites/${id}`);
  }

  updateStatus(id, status) {
    return axiosIns.patch(`/websites/${id}/status`, status);
  }

  deleteWebsite(id) {
    return axiosIns.delete(`/websites/${id}`);
  }

  getWebsiteBillingDescriptorsByWebsiteId(id) {
    return axiosIns.get(`/website-descriptors/website/${id}`);
  }

  storeWebsiteBillingDescriptor(websiteId, websiteBillingDescriptor) {
    return axiosIns.post(`/website-descriptors/website/${websiteId}`, websiteBillingDescriptor);
  }

  updateWebsiteBillingDescriptor(websiteBillingDescriptorId, websiteBillingDescriptor) {
    return axiosIns.put(`/website-descriptors/${websiteBillingDescriptorId}`, websiteBillingDescriptor);
  }

  deleteWebsiteBillingDescriptor(websiteBillingDescriptorId) {
    return axiosIns.delete(`/website-descriptors/${websiteBillingDescriptorId}`);
  }

  getWebsitePaymentDefinitionsByWebsiteId(id) {
    return axiosIns.get(`/website-payment-definitions/website/${id}`);
  }

  storeWebsitePaymentDefinition(websiteId, paymentDefinition) {
    return axiosIns.post(`/website-payment-definitions/website/${websiteId}`, paymentDefinition);
  }

  updateWebsitePaymentDefinition(paymentDefinitionId, paymentDefinition) {
    return axiosIns.put(`/website-payment-definitions/${paymentDefinitionId}`, paymentDefinition);
  }

  deleteWebsitePaymentDefinition(paymentDefinitionId) {
    return axiosIns.delete(`/website-payment-definitions/${paymentDefinitionId}`);
  }

  getWebsiteNotificationEmailsByWebsiteId(id) {
    return axiosIns.get(`/website-notification-emails/website/${id}`);
  }

  storeWebsiteNotificationEmail(websiteId, websiteNotificationEmail) {
    return axiosIns.post(`/website-notification-emails/website/${websiteId}`, websiteNotificationEmail);
  }

  updateWebsiteNotificationEmail(websiteNotificationEmailId, websiteNotificationEmail) {
    return axiosIns.put(`/website-notification-emails/${websiteNotificationEmailId}`, websiteNotificationEmail);
  }

  deleteWebsiteNotificationEmail(websiteNotificationEmailId) {
    return axiosIns.delete(`/website-notification-emails/${websiteNotificationEmailId}`);
  }
}

export default new WebsiteService();
