import axiosIns from '@axios';

class ChargebackPriceConfigService {
  getEventPriceConfig() {
    return axiosIns.get('/chargeback-price-configs');
  }

  updateEventPrice(chargebackEventPrice) {
    return axiosIns.patch('/chargeback-price-configs', chargebackEventPrice);
  }
}

export default new ChargebackPriceConfigService();
