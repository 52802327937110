import axiosIns from '@/plugins/axios';

class WebsiteMedoroSettingsService {
  getWebsiteMedoroSettingByWebsiteId(websiteId) {
    return axiosIns.get(`/website-medoro-settings/website/${websiteId}`);
  }

  saveWebsiteMedoroSettings(websiteId, settings, gatewayKey) {
    const formData = new FormData();

    formData.append('keyIndex', settings.keyIndex);
    formData.append('autoDeposit', settings.autoDeposit);
    formData.append('merchantId', settings.merchantId);

    if (gatewayKey) {
      formData.append('gatewayKey', gatewayKey);
    }

    return axiosIns.post(`/website-medoro-settings/website/${websiteId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
}

export default new WebsiteMedoroSettingsService();
