<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="390"
  >
    <v-card
      class="included">
      <v-card-title class="justify-center text-h5">
        2FA
      </v-card-title>

      <v-card-text>
       <div class="w-full d-flex flex-column text-center justify-center">
         <span v-if="twoFaType === twoFaTypeEnum.PHONE">Verification code was sent to {{phone}}.</span>
         <span v-else>Enter the code from the authenticator.</span>
         <br>
         <br>
         <v-text-field
           v-model="code"
           :rules="[validators.required]"
           :error-messages="twoFaErrors.code"
           @change="twoFaErrors.code = null"
           outlined
           dense
           id="code"
           label="Enter the code"
         ></v-text-field>
       </div>
        <div v-if="twoFaType === twoFaTypeEnum.PHONE">
          <div class="w-full d-flex text-center justify-center mt-3" v-if="countDown">You can request a new code in {{ countDown }} seconds</div>
          <div v-else class="d-flex justify-center">
            <v-btn text color="primary" @click="resendCode()">
              Resend code
            </v-btn>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="d-flex justify-space-between mt-3">
          <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> Submit </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { eventBus } from '@/main';
import { getProfile } from '@/services/jwt.service';
import { ref } from '@vue/composition-api';
import store from '@/store';
import { required } from '@core/utils/validation';
import handleError from '@/plugins/handle-error';
import Vue from 'vue';
import { TwoFaTypeEnum } from '@/enums/two-fa-type.enum';

export default {
  setup() {
    const countDown = ref(60);
    const code = ref(null);
    const twoFaTypeEnum = TwoFaTypeEnum;
    const dialog = ref(false);
    const phone = ref(null);
    const email = ref(null);
    const twoFaType = ref(null);
    const twoFaErrors = ref({ code: null });
    const countDownTimer = () => {
      if (countDown.value < 0) {
        countDown.value = 0;
      }
      if (countDown.value > 0) {
        setTimeout(() => {
          countDown.value -= 1;
          countDownTimer();
        }, 1000);
      }
    };

    const resendCode = () => {
      store.dispatch('profile/send2fa', { email: email.value }).then(() => {
        countDown.value = 60;
        countDownTimer();
      });
    };

    const openDialog = () => {
      dialog.value = true;
    };

    const onSubmit = () => {
      if (email.value) {
        store.dispatch('auth/code', { email: email.value, code: code.value }).then(() => {
          success2fa();
        }).catch(error => {
          twoFaErrors.value = { code: handleError(error) };
        });
      } else {
        store.dispatch('profile/check2fa', { code: code.value }).then(() => {
          success2fa();
        }).catch(error => {
          twoFaErrors.value = { code: handleError(error) };
        });
      }

      const success2fa = () => {
        Vue.notify({
          type: 'success',
          title: '2FA',
          text: 'Successfully completed two-factor authentication.',
          duration: 5000,
        });
        code.value = null;
        countDown.value = 60;
        dialog.value = false;
      };
    };

    return {
      code,
      phone,
      email,
      twoFaType,
      onSubmit,
      countDown,
      countDownTimer,
      resendCode,
      openDialog,
      twoFaErrors,
      dialog,
      twoFaTypeEnum,
      validators: { required },
    };

  },
  mounted() {
    eventBus.$on('2fa-dialog.show', (data) => {
      if (data && data.twoFaType) {
        this.phone = data.phone;
        this.twoFaType = data.twoFaType;
        this.email = data.email;
      } else {
        this.phone = getProfile().phone;
        this.twoFaType = getProfile().twoFaType;
        this.email = getProfile().email;
      }

      this.openDialog();
      if (this.twoFaType === TwoFaTypeEnum.PHONE) {
        this.openDialog();
        this.resendCode();
      } else {
        this.openDialog();
      }
    });
  }
};
</script>

<style lang="scss">
@import '@core/preset/preset/misc';
</style>
