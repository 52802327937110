import axiosIns from '@/plugins/axios';

class CompanyService {
  getCompanies() {
    return axiosIns.get('/companies');
  }

  getCompanyById(id) {
    return axiosIns.get(`/companies/${id}`);
  }

  patchCompany(id, company) {
    return axiosIns.patch(`/companies/${id}`, company);
  }
}

export default new CompanyService();
