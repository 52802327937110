import jwtDecode from 'jwt-decode';
import store from '@/store';

const ID_TOKEN_KEY = 'token';
const ID_TEMPORARY_LOGIN_KEY = 'temporary_merchant_token';
const ID_TEMPORARY_REFRESH_KEY = 'temporary_merchant_refresh_token';
const ID_TEMPORARY_LOGIN_EXP_DATE_KEY = 'temporary_merchant_token_exp_date';
const ID_REFRESH_TOKEN_KEY = 'refresh-token';
const PROFILE_KEY = 'profile';
const TEMPORARY_PROFILE_KEY = 'temporary_profile';

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getProfile = () => {
  const temporaryProfile = window.sessionStorage.getItem(TEMPORARY_PROFILE_KEY);
  const profile = window.localStorage.getItem(PROFILE_KEY);

  if (temporaryProfile) {
    return JSON.parse(temporaryProfile);
  }

  if (profile) {
    return JSON.parse(profile);
  }

  return null;
};

export const setProfile = profile => {
  return window.localStorage.setItem(PROFILE_KEY, JSON.stringify(profile));
};

export const getUserFromToken = () => {
  const temporaryToken = window.sessionStorage.getItem(ID_TEMPORARY_LOGIN_KEY);
  const token = window.localStorage.getItem(ID_TOKEN_KEY);

  if (temporaryToken) {
    return jwtDecode(temporaryToken);
  }

  if (token) {
    return jwtDecode(token);
  }

  return null;
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(ID_REFRESH_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveRefreshToken = token => {
  window.localStorage.setItem(ID_REFRESH_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_REFRESH_TOKEN_KEY);
};

export const saveTemporaryLoginToken = token => {
  window.sessionStorage.setItem(ID_TEMPORARY_LOGIN_KEY, token);
};

export const saveTemporaryLoginRefreshToken = token => {
  window.sessionStorage.setItem(ID_TEMPORARY_REFRESH_KEY, token);
};

export const saveTemporaryProfile = profile => {
  window.sessionStorage.setItem(TEMPORARY_PROFILE_KEY, JSON.stringify(profile));
};

export const saveTemporaryLoginTokenExpirationDate = date => {
  window.sessionStorage.setItem(ID_TEMPORARY_LOGIN_EXP_DATE_KEY, date);
};

export const getTemporaryLoginToken = () => {
  return window.sessionStorage.getItem(ID_TEMPORARY_LOGIN_KEY);
};

export const getTemporaryLoginRefreshToken = () => {
  return window.sessionStorage.getItem(ID_TEMPORARY_REFRESH_KEY);
};

export const getTemporaryLoginTokenExpirationDate = () => {
  return window.sessionStorage.getItem(ID_TEMPORARY_LOGIN_EXP_DATE_KEY);
};

export const destroyTemporaryLoginToken = () => {
  window.sessionStorage.removeItem(ID_TEMPORARY_LOGIN_KEY);
  window.sessionStorage.removeItem(ID_TEMPORARY_REFRESH_KEY);
};

export const deleteTemporaryLoginTokenExpirationDate = () => {
  window.sessionStorage.removeItem(ID_TEMPORARY_LOGIN_EXP_DATE_KEY);
};

export const destroyRefreshToken = () => {
  window.localStorage.removeItem(ID_REFRESH_TOKEN_KEY);
};

export const destroyTemporaryProfile = () => {
  window.sessionStorage.removeItem(TEMPORARY_PROFILE_KEY);
};

export const isAdmin = () => {
  let role = store.getters['auth/role'];
  if (!role) {
    role = getProfile().role;
  }
  return role === 'admin';
};

export default {
  isAdmin,
  getRefreshToken,
  getToken,
  getProfile,
  setProfile,
  saveRefreshToken,
  saveToken,
  destroyRefreshToken,
  destroyToken,
  getUserFromToken,
  saveTemporaryLoginToken,
  saveTemporaryLoginTokenExpirationDate,
  getTemporaryLoginToken,
  getTemporaryLoginTokenExpirationDate,
  destroyTemporaryLoginToken,
  deleteTemporaryLoginTokenExpirationDate,
  saveTemporaryProfile,
  destroyTemporaryProfile,
};
